import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ErrorToast, SuccessToast } from "./helpers/Toast";

const App = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!email) {
      setMessage("Please enter your email.");
      return;
    }

    if (!isValidEmail(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    const templateParams = {
      from_email: email,
      to_email: "jn@cedarock.com.au",
      message: `${email}`,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID, // Replace with your Email.js service ID
        process.env.REACT_APP_TEMPLATE_ID, // Replace with your Email.js template ID
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY // Replace with your Email.js public key
      )
      .then((response) => {
        SuccessToast("Registered successfully");
        setEmail(""); // Clear input after sending
        setMessage(""); // Clear input after sending
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        ErrorToast("Failed to register. Try again.");
      });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-white">
      <div className="flex flex-col items-center justify-between h-screen">
        <div className="pt-16 flex flex-col items-center md:pt-5">
          <img
            src="https://res.cloudinary.com/dmglilpa1/image/upload/v1740990520/Untitled-1-05_wdmbhm.png"
            alt="img"
            className="pt-16 md:w-80"
          />

          <div className="pt-16 w-[500px] md:w-[300px]">
            <h1 className="uppercase text-center">website launch soon</h1>
            <input
              type="email"
              placeholder="Enter your email here to register your interest"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="outline-none border-b-2 border-black mt-8 w-full text-center"
              required
            />
            {message && (
              <p className="text-center mt-2 text-sm text-red-500">{message}</p>
            )}
            <div className="w-full flex justify-center items-center">
              <button
                className="uppercase border border-black px-8 py-2 mt-4"
                onClick={sendEmail}
              >
                register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
